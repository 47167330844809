import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { MenuProps, ReduxState, usePermissions, } from 'react-admin';


import SubMenu from './SubMenu';
import { AppState } from '../types';

import ListIcon from '@material-ui/icons/List';
import MailIcon from '@material-ui/icons/Mail';
import AddIcon from '@material-ui/icons/Add';
import FileUploadIcon from '@material-ui/icons/AttachFile';
import EmailIcon from '@material-ui/icons/Email';
import ImportExportIcon from "@material-ui/icons/ImportExport";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import MoneyIcon from "@material-ui/icons/Money";
import SettingsIcon from "@material-ui/icons/Settings";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import CategoryIcon from "@material-ui/icons/Category";
import PeopleIcon from "@material-ui/icons/People";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import DescriptionIcon from '@material-ui/icons/Description';
import ReceiptIcon from '@material-ui/icons/Receipt';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { contractsFiltersDefaultValues } from "../contracts/components/ContractsFilter";
import { billingFiltersDefaultValues } from "../contracts/billing/components";
import { creditFiltersDefaultValues } from "../contracts/credit/components";
import AnnouncementIcon from '@material-ui/icons/Announcement';
import MenuItem from "./MenuItem";
import { StarBorder } from "@material-ui/icons";

const ENV = process.env.REACT_APP_ENV;

type MenuName = 'menuImport' | 'menuBilling' | 'menuContracts' | 'menuAccountManagement' | 'menuDisposition' | 'menuSettings' | 'menuFavorites';


const Menu = ({dense = false}: MenuProps) => {
    const menuImport = localStorage.getItem('menuImport') === 'true';
    const menuBilling = localStorage.getItem('menuBilling') === 'true';
    const menuContracts = localStorage.getItem('menuContracts') === 'true';
    const menuAccountManagement = localStorage.getItem('menuAccountManagement') === 'true';
    const menuDisposition = localStorage.getItem('menuDisposition') === 'true';
    const menuSettings = localStorage.getItem('menuSettings') === 'true';
    const menuFavorites = localStorage.getItem('menuFavorites') === 'true';
    const [state, setState] = useState({
        menuImport: menuImport,
        menuBilling: menuBilling,
        menuContracts: menuContracts,
        menuAccountManagement: menuAccountManagement,
        menuDisposition: menuDisposition,
        menuSettings: menuSettings,
        menuFavorites: menuFavorites
    });

    const {permissions} = usePermissions()

    const mailCount = parseInt(localStorage.getItem("vorversandmails") || "0");
    const athCount = parseInt(localStorage.getItem("athCount") || "0");
    const atrCount = parseInt(localStorage.getItem("atrCount") || "0");
    const protocolCount = parseInt(localStorage.getItem("protocolCount") || "0");
    const driverNotApprovedCount = parseInt(localStorage.getItem("driverNotApprovedCount") || "0");
    const applicationsCount = parseInt(localStorage.getItem("applicationsCount") || "0");

    const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
    useSelector((state: AppState) => state.theme); // force rerender on theme change
    const classes = useStyles();

    const handleToggle = (menu: MenuName) => {
        localStorage.setItem(menu, !state[menu] + '');
        setState(state => ({...state, [menu]: !state[menu]}));
    };

    const loadFavorites = () => {
        return JSON.parse(localStorage.getItem('favorites') || '[]');
    }

    const [favorites, setFavorites] = React.useState(loadFavorites());
    React.useEffect(() => {
        const handleFavoritesUpdate = () => {
            setFavorites(loadFavorites());
        };

        window.addEventListener('favoritesUpdated', handleFavoritesUpdate);
        window.addEventListener('storage', handleFavoritesUpdate);


        // Cleanup function
        return () => {
            window.removeEventListener('favoritesUpdated', handleFavoritesUpdate);
            window.removeEventListener('storage', handleFavoritesUpdate);
        };
    }, []);

    function checkPermission(requiredRoles: string[] = []) {
        requiredRoles.push('vermaco_admin')
        let result = false;
        requiredRoles.forEach(role => {
            if (permissions && permissions.includes(role)) {
                result = true;
            }
        })

        return result;
    }

    const showUnapprovedDrivers = checkPermission(['vermaco_dispo', 'vermaco_personal', 'vermaco_office_l2']);

    return (
        <div className={classnames(classes.root, {[classes.open]: open, [classes.closed]: !open,})}>
            {favorites.length > 0 && <SubMenu handleToggle={() => handleToggle('menuFavorites')}
                     isOpen={state.menuFavorites}
                     name="Favoriten"
                     icon={<StarBorder />}>
                {favorites.map(item =>
                    <MenuItem
                        to={item.to}
                        primaryText={item.primaryText}
                    />
                )}
            </SubMenu>}
            <MenuItem
                requiredRoles={['vermaco_dispo', 'vermaco_office', 'vermaco_office_l2', 'vermaco_buchhaltung']}
                to={{
                    pathname: "/contracts",
                    search: `filter=${JSON.stringify(contractsFiltersDefaultValues)}`,
                }}>
                Aufträge
            </MenuItem>

            <MenuItem
                primaryText={"Protokollversand"}
                count={protocolCount}
                requiredRoles={['vermaco_dispo', 'vermaco_office', 'vermaco_office_l2', 'vermaco_buchhaltung']}
                to={"/protocols"}
                leftIcon={<ListIcon htmlColor={protocolCount > 0 ? 'red' : ''}/>}
            />

            <MenuItem
                primaryText={"Vorversand"}
                count={mailCount}
                requiredRoles={['vermaco_dispo', 'vermaco_office', 'vermaco_office_l2']}
                to={"/mails"}
                leftIcon={<MailIcon htmlColor={mailCount > 0 ? 'red' : ''}/>}
            />

            <SubMenu
                requiredRoles={['vermaco_dispo', 'vermaco_buchhaltung']}
                handleToggle={() => handleToggle('menuAccountManagement')}
                isOpen={state.menuAccountManagement}
                name="Accountverwaltung"
                icon={<AccountCircleIcon/>}
            >
                <MenuItem
                    to="/drivers"
                    primaryText={"Fahrer"}
                    count={showUnapprovedDrivers ? driverNotApprovedCount : 0}
                    leftIcon={<TransferWithinAStationIcon htmlColor={showUnapprovedDrivers && driverNotApprovedCount > 0 ? 'red' : ''}/>}
                />
                <MenuItem
                    to="/roles"
                    primaryText="Rollen"
                    leftIcon={<VpnKeyIcon/>}
                />
                <MenuItem
                    to="/job_categories"
                    primaryText="Job Kategorien"
                    leftIcon={<CategoryIcon/>}
                />
                <hr style={{'marginRight': '30px', 'marginLeft': '30px'}}/>
                <MenuItem
                    to="/customers"
                    primaryText="Kunden"
                    leftIcon={<PeopleIcon/>}
                />
                <MenuItem
                    to="/customer_departments"
                    primaryText="Abteilungen / Filialen"
                    leftIcon={<PeopleIcon/>}
                />
                <MenuItem
                    to="/addresses"
                    primaryText="Adressen"
                    leftIcon={<LocationOnIcon/>}
                />
                <MenuItem
                    to="/categories"
                    primaryText="Kunden Kategorien"
                />
                <MenuItem
                    requiredRoles={['vermaco_admin']}
                    to="/price_groups"
                    primaryText="Preisgruppen"
                    leftIcon={<MoneyIcon/>}
                />
                <MenuItem
                    to="/billing_addresses"
                    primaryText="Rechnungsadressen"
                    leftIcon={<LocationOnIcon/>}
                />
                <MenuItem
                    to="/user_groups"
                    primaryText="Gruppen"
                    leftIcon={<PeopleIcon/>}
                />
                <MenuItem
                    to="/leasing_protocols"
                    primaryText="Leasing-Protokolle"
                    leftIcon={<DescriptionIcon/>}
                />
            </SubMenu>


            <SubMenu
                requiredRoles={['vermaco_dispo', 'vermaco_buchhaltung']}
                handleToggle={() => handleToggle('menuDisposition')}
                isOpen={state.menuDisposition}
                name="Disposition"
                icon={<DirectionsCarIcon/>}>
                <MenuItem
                    to="/contracts/create"
                    primaryText="Neuer Auftrag"
                    leftIcon={<AddIcon/>}
                />
              <MenuItem
                to={{
                    pathname: "/disposition",
                    search: `filter=${JSON.stringify(contractsFiltersDefaultValues)}`,
                }}
                primaryText="Auftragsliste"
                leftIcon={<ListIcon/>}
                dense={dense}
              />
                <MenuItem
                    to="/disposition_locks"
                    primaryText="Sperrtage"
                    leftIcon={<CalendarTodayIcon/>}
                />
                <MenuItem
                    to="/applications"
                    primaryText={"Bewerbungen"}
                    count={applicationsCount}
                    leftIcon={<ListIcon htmlColor={applicationsCount > 0 ? 'red' : ''}/>}
                />
                <MenuItem
                    to="/bundles"
                    primaryText="Fahrtpakete"
                />
                <MenuItem
                    to="/drivers"
                    primaryText="Fahrer"
                    leftIcon={<TransferWithinAStationIcon/>}
                />
                <MenuItem
                    to="/protocol_complaint_types"
                    primaryText="Beanstandungen"/>
            </SubMenu>

            <SubMenu
                requiredRoles={['vermaco_dispo']}
                handleToggle={() => handleToggle('menuImport')}
                isOpen={state.menuImport}
                name="Import"
                icon={<ImportExportIcon/>}>
                <MenuItem to="/vw_imports">
                    VW Import
                </MenuItem>

                <MenuItem to="/import" leftIcon={<FileUploadIcon/>}>
                    VW File Upload
                </MenuItem>

                <MenuItem
                    to="/athlon_import"
                    leftIcon={<EmailIcon htmlColor={athCount > 0 ? 'red' : ''}/>}
                    primaryText="Athlon Import"
                    count={athCount} />

                <MenuItem
                    to="/atr_import"
                    leftIcon={<EmailIcon htmlColor={atrCount > 0 ? 'red' : ''}/>}
                    primaryText="Atr Import"
                    count={atrCount} />
            </SubMenu>

            <SubMenu
                requiredRoles={['vermaco_buchhaltung', 'vermaco_office', 'vermaco_office_l2']}
                handleToggle={() => handleToggle('menuBilling')}
                isOpen={state.menuBilling}
                name="Buchhaltung"
                icon={<ReceiptIcon/>}>
                <MenuItem
                    requiredRoles={['vermaco_buchhaltung']}
                    to={{
                        pathname: "/billing_contracts",
                        search: `filter=${JSON.stringify({...billingFiltersDefaultValues})}`,
                    }}
                    leftIcon={<MoneyIcon/>}>
                    HS Rechnung erstellen
                </MenuItem>

                <MenuItem
                    requiredRoles={['vermaco_buchhaltung']}
                    to={{
                        pathname: "/credit_contracts",
                        search: `filter=${JSON.stringify(creditFiltersDefaultValues)}`
                    }}
                    leftIcon={<MoneyIcon/>}>
                    HS Gutschrift erstellen
                </MenuItem>

                <MenuItem requiredRoles={['vermaco_buchhaltung']} to="/invoices">
                    HS Rechnungen
                </MenuItem>

                <MenuItem requiredRoles={['vermaco_buchhaltung']} to="/billings">
                    LF Rechnungen
                </MenuItem>

                <MenuItem to="/journal" leftIcon={<AccountBalanceWalletIcon/>}>
                    Auslagen Mitarbeiter
                </MenuItem>

                <MenuItem to={'/statistics'}>
                    Statistiken
                </MenuItem>
            </SubMenu>

            <MenuItem
                requiredRoles={['vermaco_dispo', 'vermaco_office_l2']}
                to="/logbook"
                primaryText="Fahrtenbuch"
                leftIcon={<MenuBookIcon/>}
            />

            <SubMenu
                requiredRoles={['vermaco_dispo', 'vermaco_office_l2']}
                handleToggle={() => handleToggle('menuSettings')}
                isOpen={state.menuSettings}
                name="Einstellungen"
                icon={<SettingsIcon/>}
            >
                <MenuItem
                    to="/services"
                    primaryText="Zusatzleistungen"
                    leftIcon={<AddIcon/>}
                />

                <MenuItem
                    to="/accessories"
                    primaryText="Zubehör"
                />
                <MenuItem
                    to="/bodyparts"
                    primaryText="Karosserie/Bauteile"
                    leftIcon={<DirectionsCarIcon/>}
                />
                <MenuItem
                    to="/logs"
                    primaryText="Logs"
                />
                <MenuItem
                    to="/price_types"
                    primaryText="Preislistentypen"
                    leftIcon={<MoneyIcon/>}
                dense={dense}
                />
                <MenuItem
                    to="/mail_logs"
                    primaryText="Mail Logs"
                    leftIcon={<ListIcon/>}
                    dense={dense}
                />
                <MenuItem
                    to="/app_events"
                    primaryText="App Events"
                    leftIcon={<ListIcon/>}
                    dense={dense}
                />
                <MenuItem
                    to="/protocolImages"
                    primaryText="Protokollbilder"
                    leftIcon={<ListIcon/>}
                    dense={dense}
                />
            </SubMenu>

            <MenuItem
                to="/news"
                primaryText="News"
                leftIcon={<AnnouncementIcon/>}
            />
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    open: {
        width: 250,
    },
    closed: {
        width: 55,
    },
    active: {
        fontWeight: 'bold',
    },
}));

export default Menu;
