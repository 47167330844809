import React from "react";
import { FunctionField, TextField } from "react-admin";
import HotList from "../utils/components/HotList";
import EntityLink from "../utils/components/EntityLink";
import SettingsIcon from "@material-ui/icons/Settings";
import * as ButtonStyles from "../utils/components/styles/ButtonStyles";
import { defaultButtonStyle } from "../utils/components/styles/ButtonStyles";
import { Box } from "@material-ui/core";
import { ExportPDFButton } from "../contracts/components/ListButtons";
import { SendButton } from "./components/SendButton";
import { InfoButton, InfoButtonOnlyView } from "../contracts/components/InfoField";
import { DriverField, DriverViewField } from "../contracts/components/DriverField";
import ImageIcon from "@material-ui/icons/Image";


export const MailList = ( props ) => (
  <HotList {...props}
           sort={"createdAt"}
           sortOrder={"DESC"}
           filter={{ isSend: false }}>
    <FunctionField label="Auftragsnummer" render={(record) => {
      return <Box display={"flex"} alignItems={"center"}>
        <TextField source={"protocol.orderNumber"} label={"Auftragsnummer"}/>
        {!record.protocol?.allUploadsDone && <ImageIcon style={{display: "flex", marginLeft: "5px", color: "#DC3545"}} title={"Es wurden noch nicht alle Bilder hoch geladen"}/>}
      </Box>
    }}/>

    <TextField source="protocol.translatedUStatus" label={"Status"} />

    <FunctionField
      label=""
      render={( record ) => (
        <div style={{ display: "flex", flexFlow: "row", alignItems: "center" }}>
          <DriverViewField record={record.protocol.contract}/>&nbsp;<TextField record={record.protocol.contract} source={"contractClientAddress.kuerzel"}/>
        </div>    )}/>
    <FunctionField
      label=""
      render={( record ) => (
        <InfoButtonOnlyView record={record.protocol.contract}/>
    )}/>

    <TextField source={"createdAtFormatted"} label={"Datum"} sortBy="createdAt"/>
    <TextField source={"recipient"} label={"Empfänger-Adresse"}/>

    <FunctionField
      label=""
      render={( record ) =>
        <Box align="right" style={{ display: "flex", justifyContent: "flex-end", verticalAlign: "middle" }}>
          <EntityLink record={record?.protocol} icon={<SettingsIcon/>} title={"Admin"} style={defaultButtonStyle}/>
          <ExportPDFButton
            record={record.protocol.contract}
            style={ButtonStyles.noMargin}
            selectOnClick={true}
          />
          <SendButton record={record} style={ButtonStyles.noMargin}/>
        </Box>
      }
    />
  </HotList>
);

