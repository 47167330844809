import * as React from "react";
import { ArrayInput, Create, Edit, FormWithRedirect, SaveButton } from "react-admin";
import DefaultEditActions from "../utils/DefaultEditActions";
import TimeRangeInput from "../utils/components/TimeRangeInput";
import { CategoryReferenceInput } from "../category/components/CategoryReferenceInput";
import { BooleanInput, TextInput as CustomTextInput } from "../utils/components";
import { Box, Card, Toolbar } from "@material-ui/core";
import RefreshButton from "../utils/components/RefreshButton";
import CustomBooleanInput from "../utils/components/CustomBooleanInput";
import { FormTabContainerBox, InputContainer, ParagraphBox, ParagraphHeadline, Spacer } from "../utils/components/FormElements";
import { useState } from "react";
import useLocalStorage from "../utils/useLocalStorage";
import ContactFormIterator from "../utils/components/InputComponents/ArrayInput/ContactFormIterator";
import { UMailFormIterator } from "./components/UMailFormIterator";


const TextInput = (props) => {
  return <InputContainer {...props}>
    <CustomTextInput fullWidth {...props} />
  </InputContainer>
}

const EntityForm = (props) => {
  return <FormWithRedirect
    {...props}
    render={(formProps) => {

      return <form autocomplete="off" style={{marginBottom: "4rem"}}>
        <Card>
          <ParagraphBox flexWrap={"wrap"}>
            <FormTabContainerBox flex={1} minWidth={"550px"}>

              <ParagraphHeadline>Adress-Daten</ParagraphHeadline>

              <ParagraphBox>
                <TextInput source={"firma"} left />
                <TextInput source={"vorname"} center />
                <TextInput source={"nachname"} right />
              </ParagraphBox>

              <ParagraphBox>
                <TextInput source={"street"} label={"Strasse"} left />
                <TextInput source={"hausnr"} center />

                <TextInput source={"plz"} center />
                <TextInput source={"city"} label={"Ort"} center />
                <TextInput source={"land"} right />
              </ParagraphBox>

              <ParagraphBox>
                <TextInput source={"tel"} left />
                <TextInput source={"fax"} center />
                <TextInput source={"email"} right/>
              </ParagraphBox>

              <ParagraphBox>
                <InputContainer left>
                  <TimeRangeInput helperText={false}  source={"startzeit"} fullWidth />
                </InputContainer>

                <InputContainer center>
                  <TimeRangeInput helperText={false} source={"endzeit"} fullWidth right />
                </InputContainer>

                <InputContainer right>
                  <CategoryReferenceInput source={"kategorie"}/>
                </InputContainer>

              </ParagraphBox>

              <ParagraphBox>
                <TextInput source={"suchname"} left />
                <TextInput source={"name"} center />
                <TextInput source={"beschreibung"} right/>
              </ParagraphBox>

            </FormTabContainerBox>

            <FormTabContainerBox flex={1} minWidth={"550px"}>
              <ParagraphHeadline>Ansprechpartner</ParagraphHeadline>

              <ParagraphBox>
                <TextInput source={"ansprechparter1"} label={"Name Ansprechpartner 1"} left />
                <TextInput source={"anspr1telefon"} label={"Telefon Ansprechpartner 1"} center />
                <TextInput source={"anspr1email"} label={"E-Mail Ansprechpartner 1"} right />
              </ParagraphBox>

              <ParagraphBox>
                  <TextInput source={"ansprechpartner2"} label={"Name Ansprechpartner 2"} left />
                  <TextInput source={"anspr2telefon"} label={"Telefon Ansprechpartner 2"} center />
                  <TextInput source={"anspr2email"} label={"E-Mail Ansprechpartner 2"} right />
              </ParagraphBox>

              <ParagraphBox>
                  <TextInput source={"ansprechpartner3"}  label={"Name Ansprechpartner 3"} left  />
                  <TextInput source={"anspr3telefon"} label={"Telefon Ansprechpartner 3"} center  />
                  <TextInput source={"anspr3email"} label={"E-Mail Ansprechpartner 3"}  right />
              </ParagraphBox>

              <Spacer />

              <ParagraphBox>
                <ArrayInput source="contacts" label={""}>
                  <ContactFormIterator />
                </ArrayInput>
              </ParagraphBox>
            </FormTabContainerBox>

            <FormTabContainerBox flex={1} minWidth={"550px"}>
              <ParagraphHeadline>Berechtigungen</ParagraphHeadline>

              <TextInput source={"passwort"} disabled/>
              <TextInput source={"kundennummer"} />
              <TextInput source={"erlaubtekunden"} label={"Erlaubte Kunden"}  />

              <ParagraphBox>
                <InputContainer left>
                  <CustomBooleanInput source={"inangebotsformular"} label={"In Angebotsformular"}/>
                </InputContainer>
                <InputContainer right>
                  <CustomBooleanInput source={"benoetigtauftragsfreigabe"} label={"Benötigt Auftragsfreigabe"} />
                </InputContainer>
              </ParagraphBox>
              <BooleanInput source={"createdByCustomer"} label={"Vom Kunden erstellt"} />
            </FormTabContainerBox>
          </ParagraphBox>
        </Card>

        <Spacer space="1rem"/>

        <Card>
          <ParagraphBox>
            <FormTabContainerBox>
              <ParagraphHeadline>Automatischer Mailversand</ParagraphHeadline>

              <ArrayInput source="addressMails" label={""}>
                <UMailFormIterator />
              </ArrayInput>
            </FormTabContainerBox>
          </ParagraphBox>
        </Card>

        <Toolbar
          style={{
            position: "fixed",
            zIndex: 100000,
            bottom: 10,
            maxWidth: "100%",
            backgroundColor: "rgba(255,255,255, 0.8)",
            borderRadius: "5px",
          }}
        >
          {props.isCreate ? <Box width="100%">
                            <SaveButton
                              saving={props.saving}
                              handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                              label={"Adresse anlegen"}
                              style={{ marginRight: "0.5rem" }}
                            />
                          </Box> :
          <Box width="100%">
            <SaveButton
              saving={props.saving}
              handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              transform={data => {  return { ...data, strasse: data.street, ort: data.city } } }
              label={"Speichern und schließen"}
              style={{ marginRight: "0.5rem" }}
            />
             <SaveButton
              saving={props.saving}
              redirect={false}
              label={"Speichern"}
              transform={data => {  return { ...data, strasse: data.street, ort: data.city } } }
              handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              style={{ marginRight: "0.5rem" }}
            />

            <RefreshButton/>
          </Box>}
        </Toolbar>
      </form>
    }} />
  };

const EntityTitle = ({ record }) => {
  return <span>Adresse {record ? `"${record.firma}" [id:${record.originId}]` : ""}</span>;
};

const EntityEdit = (props) => (
  <Edit
    actions={<DefaultEditActions allowNew={true} {...props} />}
    title={<EntityTitle />}
    component={"div"}
    {...props}
  >
    <EntityForm />
  </Edit>
);

const EntityCreate = (props) => (
  <Create
    actions={<DefaultEditActions allowNew={true} {...props} />}
    {...props}
    title={"Neuen Eintrag anlegen"}
  >
    <EntityForm style={{ width: "100%" }} isCreate={true}/>
  </Create>
);

export { EntityEdit, EntityCreate };
