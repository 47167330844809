import { Chip, Tooltip } from "@material-ui/core";
import { Confirm, useDataProvider, useNotify, useRedirect } from "react-admin";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import Link from "./Link";


export const red = "#DC3545";
export const orange = "#C97F1F";
export const blue = "#007BFF";

export const lightblue = "#00B2FF";
export const info = "#17A2B8";
export const purple = "#8A179A";
export const white = "white";
export const green = "#28A745";

export const ProtocolListTags = ( { record } ) => {
  if( !record ) {
    return null;
  }


  const tags = [];
  if( record[ '@type' ] === 'Contract' ) {
    record.vorholung && tags.push( {
                                     name: "Vorholung",
                                     shortcode: "VH",
                                     color: white,
                                     backgroundColor: record.isDeliveryDayItem ? "gray" : "orange",
                                   } );

    record.isStorniert && tags.push( {
                                       name: "Storniert",
                                       shortcode: "STORNIERT",
                                       color: white,
                                       backgroundColor: red,
                                     } );

    record.abcfahrt && tags.push( {
                                    name: "ABC-Fahrt",
                                    shortcode: "ABC",
                                  } );

    record.abafahrt && tags.push( {
                                    name: "ABA-Fahrt",
                                    shortcode: "ABA",
                                  } );

    record.vwstationsfahrt &&
    tags.push( {
                 name: "Stationsfahrt",
                 color: white,
                 backgroundColor: blue,
                 shortcode: "SF",
               } );
    record.flexContract &&
    tags.push( {
                 name: "Flex",
                 color: white,
                 backgroundColor: orange,
                 shortcode: "FLEX",
               } );

    // record.infoBackend &&
    // tags.push( {
    //              name: record.infoBackend,
    //              color: white,
    //              backgroundColor: green,
    //              shortcode: "INFO",
    //              // styles: { padding: "4px 6px" },
    //            } );

  } else if( record[ '@type' ] === 'Protocol' ) {
    record.emptyRunDone && tags.push( {
                                        name: "Leerfahrt",
                                        shortcode: "LF",
                                        color: white,
                                        backgroundColor: purple,
                                      } );
  }

  return tags.map( ( tag ) => (
    <Tooltip title={tag.name} key={tag.shortcode} arrow interactive>
      <Chip
        label={tag.shortcode}
        style={{
          backgroundColor: tag.backgroundColor || "orange",
          color: tag.color,
          marginRight: "2px",
        }}
        size="small"
      />
    </Tooltip>
  ) );

}

export const ContractTags = ( { record } ) => {
  const [open, setOpen] = useState( false );
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const tags = [];
  const redirect = useRedirect();
  const history = useHistory();

  record.leerfahrt === "1" && tags.push( {
                                           name: "Leerfahrt",
                                           shortcode: "LF",
                                           color: white,
                                           backgroundColor: purple,
                                         } );

  record.verzoegert &&
  tags.push( {
               name: "Verzögert",
               shortcode: "VZ",
               color: white,
               backgroundColor: red,
             } );
  record.neumerker === '1' &&
  tags.push( {
               name: "Neu",
               shortcode: "NEU",
               color: white,
               backgroundColor: lightblue,
             } );

  record.vorholung &&
  tags.push( {
               name: "Vorholung",
               shortcode: "VH",
               color: white,
               backgroundColor: record.isDeliveryDayItem ? "gray" : "orange",
             } );

  record.isStorniert &&
  tags.push( {
               name: "Storniert",
               shortcode: "STORNIERT",
               color: white,
               backgroundColor: red,
             } );

  record.abcfahrt &&
  tags.push( {
               name: "ABC-Fahrt",
               shortcode: "ABC",
             } );

  record.abafahrt &&
  tags.push( {
               name: "ABA-Fahrt",
               shortcode: "ABA",
             } );

  record.unbestaetigt &&
  tags.push( {
               name: "Auftrag Unbestätigt",
               color: white,
               backgroundColor: info,
               shortcode: "UB",
             } );

  record.vwstationsfahrt &&
  tags.push( {
               name: "Stationsfahrt",
               color: white,
               backgroundColor: blue,
               shortcode: "SF",
             } );

  record.flexContract &&
  tags.push( {
               name: "Flex",
               color: white,
               backgroundColor: orange,
               shortcode: "FLEX",
             } );


  let tagCollection = tags.map( ( tag ) => (
    <Tooltip title={tag.name} key={tag.shortcode} arrow interactive>
      <Chip
        label={tag.shortcode}
        style={{
          backgroundColor: tag.backgroundColor || "orange",
          color: tag.color ?? white,
          marginRight: "2px",
          ...tag.styles
        }}
        size="small"
      />
    </Tooltip>
  ) )

  if( record.cancellationRequested ) {
    tagCollection.push( <>
      <Confirm
        isOpen={open}
        title="Stornierung bestätigen"
        content="Möchten Sie den Stornierungswunsch bestätigen?"
        onClose={() => setOpen( false )}
        onConfirm={() => {
          dataProvider.update( "contracts", {
            id: record[ '@id' ],
            data: {
              isStorniert: true,
              stornierung: record.cancellationRequested,
              cancellationRequested: null,
            },
          } ).then( ( response ) => {
            setOpen( false );
            notify( "Stornierung bestätigt", { type: "success" } );
          } ).catch( ( error ) => {
            notify( `Fehler beim bearbeiten: ${error.message}`, { type: "warning" } );
          } )
        }}
      />
      <Tooltip title={`Stornierung am ${record.cancellationRequestedFormatted} bestätigen`} arrow interactive onClick={() => setOpen( true )}>
        <Chip
          label="STORNO"
          style={{
            backgroundColor: info,
            color: white,
            marginRight: "2px",
          }}
          size="small"
        />
      </Tooltip>
    </> )
  }

  if( record.isPartOfBundle ) {
    for( const bundle of record.bundles ) {
      tagCollection.push(
        <Tooltip
          title={"Ist in Fahrtpaket"}
          key={bundle.bundle}
          arrow
          interactive
          onClick={() => window.open(`#/bundles/${bundle['@id']?.replaceAll( '/', '%2F' )}`, '_blank')}
        >
          <Chip
            label={bundle.bundle}
            style={{
              backgroundColor: "orange",
              color: white,
              marginRight: "2px"
            }}
            size="small"
          />
        </Tooltip>
      )
    }
  }


  return tagCollection
};

export const BillingTags = ( { record } ) => {
  const tags = [];

  const getRechnungsAddresse = ( address ) => {
    let addressString = "";
    if( address.firma ) {
      addressString += address.firma+" | ";
    }
    if( address.name ) {
      addressString += address.name+" | ";
    }
    if( address.strasse ) {
      addressString += address.strasse+" "+address.hausnr+" ";
    }
    if( address.plz ) {
      addressString += address.plz+" "+address.ort;
    }
    return addressString;
  };
  const getLeistungsAddresse = ( address ) => {
    let addressString = "";
    if( address.firma ) {
      addressString += address.firma+" | ";
    }
    if( address.name ) {
      addressString += address.name+" | ";
    }
    if( address.strasse ) {
      addressString += address.strasse+" ";
    }
    if( address.ort ) {
      addressString += address.ort;
    }
    return addressString;
  };


  record.rechnungsnotiz && tags.push( {
                                        name: record.rechnungsnotiz,
                                        shortcode: "RE",
                                        color: white,
                                        backgroundColor: red,
                                      } );

  ( record.leerfahrt === "1" || record.anschlussAuftrag?.leerfahrt === "1" ) && tags.push( {
                                                                                             name: "Leerfahrt",
                                                                                             shortcode: "LF",
                                                                                             color: white,
                                                                                             backgroundColor: purple,
                                                                                           } );

  record.kostenstelle && tags.push( {
                                      name: record.kostenstelle,
                                      shortcode: "KS",
                                      color: white,
                                      backgroundColor: green,
                                    } );

  record.billingClientAddress &&
  tags.push( {
               name: getRechnungsAddresse( record.billingClientAddress ),
               shortcode: "RA",
               color: white,
               backgroundColor: green,
             } );

  record.leistungsadresse &&
  tags.push( {
               name: getLeistungsAddresse( record.leistungsadresse ),
               shortcode: "LA",
               color: white,
               backgroundColor: green,
             } );


  record.abcfahrt &&
  tags.push( {
               name: "ABC-Fahrt",
               shortcode: "ABC",
             } );

  record.abafahrt &&
  tags.push( {
               name: "ABA-Fahrt",
               shortcode: "ABA",
             } );

  record.vwstationsfahrt &&
  tags.push( {
               name: "Stationsfahrt",
               color: white,
               backgroundColor: blue,
               shortcode: "SF",
             } );

  return tags.map( ( tag ) => (
    <Tooltip title={tag.name} key={tag.shortcode} arrow>
      <Chip
        label={tag.shortcode}
        style={{
          backgroundColor: tag.backgroundColor || "orange",
          color: tag.color,
          marginRight: "2px",
          height: 'auto'
        }}
        size="small"
      />
    </Tooltip>
  ) );
};


export const CreditTags = ( { record } ) => {
  const tags = [];

  record.gutschriftnotiz && tags.push( {
                                         name: record.gutschriftnotiz,
                                         shortcode: "INFO GS",
                                         color: white,
                                         backgroundColor: green,
                                       } )


  record.leerfahrt === "1" && tags.push( {
                                           name: "Leerfahrt",
                                           shortcode: "LF",
                                           color: white,
                                           backgroundColor: purple,
                                         } );


  record.abcfahrt &&
  tags.push( {
               name: "ABC-Fahrt",
               shortcode: "ABC",
             } );

  record.abafahrt &&
  tags.push( {
               name: "ABA-Fahrt",
               shortcode: "ABA",
             } );

  record.vwstationsfahrt &&
  tags.push( {
               name: "Stationsfahrt",
               color: white,
               backgroundColor: blue,
               shortcode: "SF",
             } );

  return tags.map( ( tag ) => (
    <Tooltip title={tag.name} key={tag.shortcode} arrow>
      <Chip
        label={tag.shortcode}
        style={{
          backgroundColor: tag.backgroundColor || "orange",
          color: tag.color,
          marginRight: "2px",
          height: 'auto'
        }}
        size="small"
      />
    </Tooltip>
  ) );
};
